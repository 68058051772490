// import { useSelector } from 'react-redux';
// import { GeoJsonLayer } from '@deck.gl/layers';
// import {RootState} from "../../../store/store";
//@ts-ignore
import {PolygonLayer} from "@deck.gl/layers";

export const ISOCHRONE_LAYER_ID = 'isolineLayer';

export default function IsochroneLayer(geoJsonData: any) {
  // const { isolineLayer } = useSelector((state: RootState) => state.carto.layers);
  // const isolineResult = useSelector((state: RootState) => state.app.isolineResult);
  // console.log('poligono: ', geoJsonData);
  return new PolygonLayer({
    id: ISOCHRONE_LAYER_ID,
    data: geoJsonData,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: (d: any) => d.contour,
    getElevation: (d: any) => d.population / d.area / 10,
    getFillColor: (d: any) => [d.population / d.area / 60, 140, 0],
    getLineColor: [80, 80, 80],
    getLineWidth: 1
  });
  // if (isolineLayer && isolineResult) {
  //   return new GeoJsonLayer({
  //     id: ISOCHRONE_LAYER_ID,
  //     data: isolineResult,
  //     stroked: true,
  //     filled: true,
  //     lineWidthMinPixels: 1,
  //     getFillColor: [71, 219, 153, 80],
  //     getLineColor: [71, 219, 153],
  //   });
  // }
}
