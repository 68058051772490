
import { BaseMap } from 'components/views/admin/BaseMap/domain/basemap';
import api from './http-client';

export const BaseMapApi = {
  getBaseMap: async () => {
    return await api.get('/base-map', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getBaseMapId: async (id: string) => {
    return await api.get(`/base-map/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  createBaseMap: async (baseMap: BaseMap) => {
    return await api.post('/base-map', baseMap, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  updateBaseMap: async (baseMap: BaseMap, id: string) => {
    return await api.patch(`/base-map/${id}`, baseMap, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  deleteBaseMap: async (id: string) => {
    return await api.delete(`/base-map/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },
};
