import {CartoBasemapsNames, DARK_MATTER, VOYAGER} from '@carto/react-basemaps';
// @ts-ignore
import { API_VERSIONS } from '@deck.gl/carto';

interface State {
  viewState: {
    latitude: number,
    longitude: number,
    zoom: number,
    pitch: number,
    bearing: number,
    dragRotate: boolean,
  },
  basemap: CartoBasemapsNames,
  credentials: {
    apiVersion: string,
    apiBaseUrl: string,
    accessToken: string,
  },
  googleApiKey: string, // only required when using a Google Basemap,
  googleMapId: string, // only required when using a Google Custom Basemap
  // oauth: {
  //   domain: string,
  //   // Type here your application client id
  //   clientId: string,
  //   scopes: string[
  //   ],
  //   audience: string,
  //   authorizeEndPoint: string, // only valid if keeping https://localhost:3000/oauthCallback
  // },
}

export const initialState: State = {
  viewState: {
    latitude: -23.6821604,
    longitude: -46.8754867,
    zoom: 5,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken:
      'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfcmFlYmp2cnQiLCJqdGkiOiIzOWVkZjM4YyJ9._zlwWiB2qt9vgWldISv0_MggG_Xe5WsdGa8Z9U4sWrc',
  },
  googleApiKey: '', // only required when using a Google Basemap,
  googleMapId: '', // only required when using a Google Custom Basemap
  // oauth: {
  //   domain: 'auth.carto.com',
  //   // Type here your application client id
  //   clientId: 'asd',
  //   scopes: [
  //     'read:current_user',
  //     'update:current_user',
  //     'read:connections',
  //     'write:connections',
  //     'read:maps',
  //     'write:maps',
  //     'read:account',
  //     'admin:account',
  //   ],
  //   audience: 'carto-cloud-native-api',
  //   authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  // },
};
