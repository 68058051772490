// @ts-ignore
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';

export default function OverlayLayer(geoJsonData: any) {
  // console.log('GeoJsonLayer: ', geoJsonData);

  return new IconLayer({
    id: 'icon-layer',
    data: geoJsonData.features,
    pickable: true,
    getIcon: (d: any) => ({
      url: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      width: 128,
      height: 128,
      anchorY: 128
    }),
    sizeScale: 15,
    iconMapping: {
      marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
    },
    getPosition: (d: any) => d.geometry.coordinates,
    getSize: (d: any) => 5,
    getColor: (d: any) => [Math.sqrt(d.exits), 140, 0],

    onHover: (info: any) => {
      // console.log('Hovered info: ', info.object);
      if (info?.object) {
        info.object = {
          html:
            '<a href="https://geografiademercado.com/">' +
            '  <img src="https://casadoteatro.com.br/wp-content/themes/casadoteatro/images/whatsapp-wrapper.png"/>' +
            '</a>' +
            info.object.properties.municipio_ee + ' - ' + info.object.properties.uf,
          style: {},
        };
      }
    },
    onClick: (info: any, event: any) => {
      // console.log('Clicked:', info.object);
      if (info?.object) {
        info.object = {
          html:
            '<a href="https://geografiademercado.com/">' +
            '  <img src="https://casadoteatro.com.br/wp-content/themes/casadoteatro/images/whatsapp-wrapper.png"/>' +
            '</a>' +
            info.object.properties.municipio_ee + ' - ' + info.object.properties.uf,
          style: {},
        };
      }
    }
  });
}

// CEP
// 08674003
//
// RENDA MÉDIA DOMICILIAR (500M)
// 10.26
//
// A (500M)
// 349.14
//
// B (500M)
// 893.65
//
// C (500M)
// 645.72
//
// D+E (500M)
// 82.7
//
// DOMICÍLIOS (500M)
// 1971.22
//
// EMPREGOS (500M)
// 9664.61
