import { createSlice } from '@reduxjs/toolkit';
import OverlayLayer from "../components/layers/OverlayLayer";
import IsochroneLayer from "../components/layers/isochrone/IsochroneLayer";

const slice = createSlice({
  name: 'map',
  initialState: {
    layers: [],
  },
  reducers: {
    addIsolineLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(IsochroneLayer(action.payload.geoJsonData));
    },
    addGeojsonLayer: (state, action) => {
      // @ts-ignore
      state.layers.push(OverlayLayer(action.payload.geoJsonData));
    },
    clearLayers: (state, action) => {
      state.layers = [];
      // precisa ter um metodo na Carto pra dar um clear em todos os layers sem precisar passar o ID dispatch(removeLayer(???)); import {removeLayer} from "@carto/react-redux";
    },
  }
});

export default slice.reducer;

export const addIsolineLayer = (geoJsonData: any) => ({
  type: 'map/addIsolineLayer',
  payload: {geoJsonData},
});
export const addGeojsonLayer = (geoJsonData: any) => ({
  type: 'map/addGeojsonLayer',
  payload: {geoJsonData},
});
export const clearLayers = () => ({
  type: 'map/clearLayers',
});
