import * as React from 'react';
import { MouseEvent, useEffect, useState } from 'react';
import {
  AppBar,
  Drawer,
  Divider,
  Hidden,
  Grid,
  IconButton,
  Tabs,
  Toolbar,
  Link,
  makeStyles,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
  Box
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as CartoLogo } from 'assets/img/carto-logo.svg';
import { ReactComponent as CartoLogoXS } from 'assets/img/carto-logo-xs.svg';
import { ROUTE_PATHS } from 'routes';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { CustomTheme } from 'theme';
import { clearLayers } from "../../store/mapSlice";
import { useDispatch } from "react-redux";
import { userApi } from "./../../service/http-out/user";
import { CompanyApi } from 'service/http-out/company';
import ViewListIcon from '@mui/icons-material/ViewList';
import { AccountCircle, FolderOpen, Help, LayersClear, Logout, PushPin } from '@mui/icons-material';
import { GlobalContext } from '../../contexts/globalContext';
import { setViewState } from '@carto/react-redux';

const useStylesCommon = makeStyles((theme) => ({
  title: {
    '& h1': {
      display: 'flex',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.white,

      '& strong': {
        marginRight: theme.spacing(0.5),
      },

      '& svg': {
        height: `${theme.typography.subtitle1.lineHeight}em`,
        marginRight: theme.spacing(1.5),
        width: 'auto',
        verticalAlign: 'bottom',
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'none',
    zIndex: theme.zIndex.modal + 1,
    overflow: 'hidden',
  },
  img: {
    width: '50px',
    height: "50px",
    cursor: "pointer",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position='static' className={classes.header}>
      <Toolbar variant='dense'>
        <Mobile />
        <Desktop />
      </Toolbar>
    </AppBar>
  );
}

const useStylesDesktop = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 3),
  },
  img: {
    cursor: "pointer",
    maxWidth: "150px",
    maxHeight: "45px"
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    //float: 'left',
    width: '100%',
  },
  root2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    //justifyContent: 'space-between',
    //float: 'left',
    width: '30%',
  },
  root3: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    //justifyContent: 'space-between',
    //float: 'left',
    width: '30%',
  },
  margin: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  padding: {
    paddingTop: '30px',
  },
  beetwen: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  right: {
    alignItems: 'right',
  }
}));

function Desktop() {
  const navigate = useNavigate();
  const [logo, setLogo] = React.useState('');
  const dispatch = useDispatch();
  //@ts-ignore
  const { setCompanySignedId ,companyUserSigned, setCompanyUserSigned, setUserSigned } = React.useContext(GlobalContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function decodeToken() {
      let user = {}
      const companyId:any = localStorage.getItem('company');
      const token = localStorage.getItem('token');
      const userId: any = localStorage.getItem('id');
      // @ts-ignore
      const decoded: any = jwt_decode(token);
      if (decoded.role == 'admin') {
        setIsAdmin(true);
      }
      //Chamar api para pegar empresa
      //Empresa na api de context
      let company = {}
      CompanyApi.getCompanyByFilters(companyId, userId).then((res)=>{
        setCompanyUserSigned(res.data)
        console.log(res.data);
      }).catch((erro)=>{
        console.log(erro);
      })
      setCompanySignedId(companyId)
        dispatch(setViewState({
          latitude: -23.533773,
          longitude: -46.625290,
          zoom: 8
        }));
    }
    decodeToken();
  }, []);

  // useEffect(() => {
  //   userApi.getUserId(localStorage.getItem('id') || "")
  //     .then((res) => {
  //       setCompanyUserSigned(res.data.company)
  //       setUserSigned(res.data);
  //       setLogo(res.data.company.logo);
  //     });
  // }, []);

  const classes = {
    ...useStylesCommon(),
    ...useStylesDesktop(),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <Hidden xsDown>
      <Box className={classes.root}>

        <div className={classes.root3}>
          <Link
            component={NavLink}
            to={ROUTE_PATHS.DEFAULT}
            className={classes.title}
          >
            <Typography component='h1' variant='subtitle1' noWrap>
              <AppName />
            </Typography>
          </Link>
          <Divider
            orientation='vertical'
            className={classes.divider}
            light
          ></Divider>

          {/* <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            Empresa
          </Button> */}
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem className={classes.padding} onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>

        {isAdmin ?
          <Box>
            <IconButton onClick={logout} title='Logout'><Logout sx={{ color: 'white' }} /></IconButton>
          </Box>
          :
          <div >
            <Box className={classes.root2}>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <Divider
                orientation='vertical'
                className={classes.divider}
                light
              ></Divider>
              <div className={classes.root}>
                <img className={classes.img} src={logo} />
              </div>
              <div className={classes.root}>
                <Button
                  className={classes.root3}
                  id="demo-positioned-button"
                  aria-controls={open2 ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={handleClick2}

                >
                  <ViewListIcon sx={{color:'white'}}/>
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl2}
                  className={classes.padding}
                  open={open2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem className={classes.padding}><IconButton title='Limpar Mapa'> <LayersClear /></IconButton> </MenuItem>
                  <MenuItem > <IconButton title='Onde estou'> <PushPin /></IconButton></MenuItem>
                  <MenuItem > <IconButton title='Ajuda'> <Help /></IconButton> </MenuItem>
                  <MenuItem > <IconButton title='Projetos'> <FolderOpen /></IconButton> </MenuItem>
                  <MenuItem > <IconButton title='Alterar Senha'> <AccountCircle /></IconButton></MenuItem>
                  <MenuItem onClick={logout}> <IconButton title='Logout'> <Logout /></IconButton> </MenuItem>
                </Menu>
              </div>
              <NavigationMenu/>
            </Box>
            <Grid container item xs justifyContent='flex-end'>
              <UserMenu />
            </Grid>
          </div>
        }
      </Box>

    </Hidden>
  );
}

const useStylesMobile = makeStyles((theme) => ({
  menuButton: {
    margin: theme.spacing(0, 0.75, 0, -1.25),

    '& + hr': {
      marginRight: theme.spacing(1.5),
    },
  },
  drawer: {
    minWidth: 260,
  },
}));

function Mobile() {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = {
    ...useStylesCommon(),
    ...useStylesMobile(),
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Hidden smUp>
      <IconButton
        className={classes.menuButton}
        color='inherit'
        aria-label='menu'
        onClick={handleDrawerToggle}
      >
        {drawerOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Divider orientation='vertical' light />
      <Link
        component={NavLink}
        to={ROUTE_PATHS.DEFAULT}
        className={classes.title}
      >
        <Typography component='h1' variant='subtitle1' noWrap>
          <Hidden smUp>
            <CartoLogoXS />
            <Divider orientation='vertical' light />
          </Hidden>
          <AppName />
        </Typography>
      </Link>
      <Drawer
        variant='temporary'
        anchor='left'
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Toolbar variant='dense' />
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          item
          xs
        >
          <NavigationMenu column={true} />
        </Grid>
      </Drawer>
    </Hidden>
  );
}

function AppName() {
  return (
    <>
      <strong>Geografia de Mercado</strong>
    </>
  );
}

const useStylesNavigationMenu = makeStyles((theme: CustomTheme) => ({
  navTabs: {
    '& .MuiTabs-indicator': {
      backgroundColor:
        theme.palette.appBar?.contrastText ||
        theme.palette.primary?.contrastText,
    },
  },
}));

function NavigationMenu({ column = false }: { column?: boolean }) {
  const location = useLocation();
  const classes = useStylesNavigationMenu();

  const pathname = location.pathname.split('/')[1] || '';

  return (
    <Grid
      container
      direction={column ? 'column' : 'row'}
      className={!column ? classes.navTabs : ''}
    >
      <Tabs
        value={pathname}
        textColor={column ? 'primary' : 'inherit'}
        orientation={column ? 'vertical' : 'horizontal'}
        variant={column ? 'fullWidth' : 'standard'}
      >
        {/* [hygen] Import links */}
      </Tabs>
    </Grid>
  );
}

const useStylesUserMenu = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    marginLeft: theme.spacing(1),
  },
}));

function UserMenu() {
  const dispatch = useDispatch();
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & (HTMLAnchorElement | HTMLSpanElement)) | null
  >(null);
  const classes = useStylesUserMenu();

  // User is NOT logged in, so display nothing
  if (!user) {
    return null;
  }

  // At this point, there is an oauthApp and the user has logged in (forceOAuthLogin mode).
  const open = Boolean(anchorEl);

  const handleMenu = (
    event: MouseEvent<HTMLAnchorElement | HTMLSpanElement>,
  ) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearMap = () => {
    dispatch(clearLayers());
  };
  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    handleClose();
  };

  // Display User menu, with name, avatar + an attached menu for user-related options
  return (
    <>
      <Link
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        color='inherit'
        onClick={handleMenu}
      >
        <Grid container alignItems='center' item wrap='nowrap'>
          <Hidden smDown>
            <Typography variant='caption' color='inherit' noWrap>
              {user.email}
            </Typography>
          </Hidden>
          <Avatar className={classes.avatar} src={user.picture} />
        </Grid>
      </Link>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logo</MenuItem>
        <MenuItem onClick={handleClearMap}>Limpar Mapa</MenuItem>
        <MenuItem onClick={handleLogout}>Onde Estou</MenuItem>
        <MenuItem onClick={handleLogout}>Ajuda</MenuItem>
        <MenuItem onClick={handleLogout}>Projetos</MenuItem>
        <MenuItem onClick={handleLogout}>Ajuda</MenuItem>
        <MenuItem onClick={handleLogout}>Alterar Senha</MenuItem>
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
        <MenuItem>
          <Link href='https://geografiademercado.com/'>Ir para a Geografia de Mercado</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
// function dispatch(arg0: Function) {
//   throw new Error('Function not implemented.');
// }

