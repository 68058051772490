
import { Company } from 'components/views/admin/Company/domain/company';
import api from './http-client';

export const CompanyApi = {
  getCompany: async () => {
    return await api.get('/company', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getCompanyImages: async () => {
    return await api.get('/company/images', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getUploadType: async () => {
    return await api.get('/company/upload/types', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getCompanyId: async (id: string) => {
    return await api.get(`/company/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getCompanyByFilters: async (companyId: string, userId: string) => {
    return await api.get(`/company/${companyId}/byFilter?userId=${userId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getCompanyName: async (name: string) => {
    return await api.get(`/company/name/${name}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  uploadIMage: async (image: FormData) => {
    return await api.post('/company/uploadImage', image, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  createCompany: async (company: Company) => {
    return await api.post('/company', company, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    
  },

  updateCompany: async (company: Company, id: string) => {
    return await api.patch(`/company/${id}`, company, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  deleteCompany: async (id: string) => {
    return await api.delete(`/company/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  deleteLogo: async (id: string) => {
    return await api.delete(`/company/images/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },
};
