import { lazy } from 'react';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';
import LazyLoadComponent from './components/common/LazyLoadComponent';

const Main = lazy(
  () => import(/* webpackPrefetch: true */ 'components/views/main/Main'),
);
const NotFound = lazy(() => import('components/views/NotFound'));
const Login = lazy(() => import('components/views/Login'));
const User = lazy(() => import('components/views/admin/User/UserList'));
const UserSettings = lazy(
  () => import('components/views/admin/User/UserSettings'),
);
const UserEdit = lazy(() => import('components/views/admin/User/UserSettings'));
const Company = lazy(() => import('components/views/admin/Company/Company'));
const CompanyList = lazy(() => import('components/views/admin/Company/CompanyList'));
const UploadImage = lazy(() => import('components/views/admin/UploadImage/uploadImage'));
const BaseMap = lazy(() => import('components/views/admin/BaseMap/BaseMap'));
const BaseMapList = lazy(() => import('components/views/admin/BaseMap/BaseMapList'));
const Home = lazy(() => import('components/views/admin/Home/Home'));
const Package = lazy(() => import('components/views/admin/Package/PackageSettings'));
const PackageList = lazy(
  () => import('components/views/admin/Package/PackageList'),
);
const Filters = lazy(() => import('components/views/admin/Filters/Filters'));
const FilterSettings = lazy(() => import('components/views/admin/Filters/FilterSettings'));
const Flashdata = lazy(
  () => import('components/views/admin/Flashdata/FlashdataList'),
);
const FlashdataSettings = lazy(
  () => import('components/views/admin/Flashdata/Flashdata'),
);
const Geography = lazy(
  () => import('components/views/admin/Geography/GeographySettings'),
);
const GeographyList = lazy(
  () => import('components/views/admin/Geography/GeographyList'),
);
const Check = lazy(() => import('components/views/admin/Check/Check'));
const CheckList = lazy(() => import('components/views/admin/Check/CheckList'));
const Sidebar = lazy(() => import('components/common/Sidebar'));
const Reports = lazy(() => import('components/views/admin/Report/Report'));
const ReportList = lazy(() => import('components/views/admin/Report/ReportList'));
const ReportSecion = lazy(() => import('components/views/admin/ReportSecion/reportSecion'));
const ReportIndicators = lazy(() => import('components/views/admin/ReportIndicators/reportIndicators'));
const Functionalities = lazy(
  () => import('components/views/admin/Functionalities/Functionalities'),
);
const FunctionalitiesList = lazy(
  () => import('components/views/admin/Functionalities/FunctionalitiesList'),
);
const OverlaySettings = lazy(
  () => import('components/views/admin/OverlaySettings/OverlaySettings'),
);
const OverlayList = lazy(
  () => import('components/views/admin/OverlaySettings/OverlayList'),
);
const Datasets = lazy(() => import('components/views/admin/Datasets/Dataset'));
const Report = lazy(() => import('components/views/client/Report/Report'));
const VariableSettings = lazy(
  () => import('components/views/admin/Variable/VariableSettings'),
);
const VariableSettingsEdit = lazy(
  () => import('components/views/admin/Variable/VariableSettingsEdit'),
);
const VariableSettingsList = lazy(
  () => import('components/views/admin/Variable/VariableList'),
);
const VariableEdit = lazy(
  () => import('components/views/admin/Variable/VariableEdit'),
);
const LoginSelect = lazy(
  () => import('components/views/reports/LoginSelect/LoginSelect'),
);
const LoginReport = lazy(
  () => import('components/views/reports/LoginReport/LoginReport'),
);
const Presentation = lazy(
  () => import('components/views/reports/Presentation/Presentation')
)
const ReportMenu = lazy(
  () => import('components/views/reports/ReportMenu/ReportMenu')
)
const DataReport = lazy(
  () => import('components/views/reports/DataReport/DataReport')
)


// [hygen] Import views

export const ROUTE_PATHS = {
  LOGIN: '/login',
  DEFAULT: '/',
  NOT_FOUND: '/404',
  USER: '/user',
  USER_SETTINGS: '/userSettings',
  USEREDIT: '/userSettings/:id',
  OVERLAYS: '/overlaysSettingsList',
  OVERLAY_SETTINGS: '/overlaySettings',
  OVERLAYLIST: '/overlaySettingsList',
  COMPANY: '/company',
  COMPANYLIST: '/company-list',
  UPLOADIMAGE: '/upload-image',
  REPORT: '/report',
  DATASET: '/dataset',
  VARIABLE_SETTINGS: '/variableSettings',
  VARIABLE_SETTINGS_EDIT: '/variableSettingsEdit',
  VARIABLELIST: '/variableList',
  VARIABLE_EDIT: '/variableedit',
  HOME: '/home',
  PACKAGE: '/package',
  PACKAGELIST: '/package-list',
  FILTERS: '/filters',
  FILTER_SETTINGS: '/filterSettings',
  FLASHDATA: '/flashdata',
  FLASHDATA_SETTINGS: '/flashdataSettings',
  GEOGRAPHY: '/geography',
  GEOGRAPHY_LIST: '/geography-list',
  BASE_MAP: '/base-map',
  BASE_MAP_LIST: '/base-map-list',
  CHECK: '/check',
  CHECKLIST: '/checklist',
  REPORTS: '/reports',
  REPORTLIST: '/report-list',
  REPORTSECION: '/reportSecion',
  REPORTINDICATORS: '/reportIndicators',
  FUNCIONALITIES: '/funcionalities',
  FUNCIONALITIESLIST: '/funcionalitiesList',
  LOGIN_SELECT: '/login-select',
  LOGIN_REPORT: '/login-report',
  PRESENTATION: '/presentation',
  REPORT_MENU: '/report-menu',
  DATA_REPORT: '/data-report'
  // [hygen] Add path routes
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [
      // { path: '/', element: <Navigate to='/<your default view>' /> },
      // [hygen] Add routes
    ],
  },
  { path: ROUTE_PATHS.LOGIN, element: <Login /> },
  {
    path: '*',
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
  {
    path: ROUTE_PATHS.USER,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <User />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.USEREDIT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <UserEdit />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.USER_SETTINGS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <UserSettings />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Report />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.COMPANY,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Company />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.COMPANYLIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <CompanyList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.UPLOADIMAGE,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <UploadImage />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.OVERLAYLIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <OverlayList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.OVERLAY_SETTINGS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <OverlaySettings />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.DATASET,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Datasets />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.VARIABLE_SETTINGS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <VariableSettings />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.VARIABLE_SETTINGS_EDIT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <VariableSettingsEdit />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.VARIABLELIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <VariableSettingsList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.VARIABLE_EDIT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <VariableEdit />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.HOME,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Home />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.PACKAGE,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Package />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.PACKAGELIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <PackageList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FILTERS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Filters />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FILTER_SETTINGS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <FilterSettings />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FLASHDATA,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Flashdata />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FLASHDATA_SETTINGS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <FlashdataSettings />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.GEOGRAPHY,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Geography />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.GEOGRAPHY_LIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <GeographyList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.BASE_MAP,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <BaseMap />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.BASE_MAP_LIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <BaseMapList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.CHECK,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Check />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.CHECKLIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <CheckList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORTS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Reports />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORTLIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <ReportList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORTSECION,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <ReportSecion />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORTINDICATORS,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <ReportIndicators />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FUNCIONALITIES,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Functionalities />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.FUNCIONALITIESLIST,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <FunctionalitiesList />
        </DefaultView>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.LOGIN_SELECT,
    element: (
      <ProtectedRoute>
        <LoginSelect />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.LOGIN_REPORT,
    element: (
      <ProtectedRoute>
        <LoginReport />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.PRESENTATION,
    element: (
      <ProtectedRoute>
        <Presentation />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.REPORT_MENU,
    element: (
      <ProtectedRoute>
        <ReportMenu />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTE_PATHS.DATA_REPORT,
    element: (
      <ProtectedRoute>
        <DataReport />
      </ProtectedRoute>
    ),
  },
];

export default routes;
