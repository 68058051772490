import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTE_PATHS } from 'routes';

export default function ProtectedRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const { isAuthenticated, isLoading } = useAuth0();
  const accessToken = useSelector(
    (state) => localStorage.getItem('token'),
  );

  if (accessToken !== null) {
  // if (accessToken !== null) {
    return children;
  }

  const notAuthenticated = !isLoading && !isAuthenticated && accessToken === null;
  // if (notAuthenticated) {
  if (accessToken === null) {
    return <Navigate to={ROUTE_PATHS.LOGIN} />;
  }

  return !!accessToken ? children : null;
}
