import Header from 'components/common/Header';
import Sidebar from 'components/common/Sidebar';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function DefaultView({ children }: { children: JSX.Element }) {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Sidebar />
      {children}
    </>
  );
}
