import { useDispatch } from 'react-redux';
import React, {useEffect, useState} from 'react';
import {DARK_MATTER, POSITRON, VOYAGER} from "@carto/react-basemaps";
import {
  Box,
  Modal,
  makeStyles
} from '@material-ui/core';
import Button from '@mui/material/Button';
import './basemap.css'
import {setBasemap, setViewState} from "@carto/react-redux";
import Escuro from '../../../../assets/img/Escuro.png';
import Claro from'../../../../assets/img/Claro.png';
import Voyager from'../../../../assets/img/Voyager.png';
import Noturno from'../../../../assets/img/Noturno.png';
import Noturno_simplificado from'../../../../assets/img/Noturno_simplificado.png';
import Satelite from'../../../../assets/img/Satelite.jpg';
import Transito from'../../../../assets/img/Transito.png';
import Diurno from'../../../../assets/img/Diurno.png';
import { BaseMapApi } from "../../../../service/http-out/baseMap";
import { GlobalContext } from '../../../../contexts/globalContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  editFilter: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    flexDirection: 'row',
  },

  scrollFilter: {
    overflowY: 'scroll',
  },

  img: {
    width: '200px',
    height: '200px',
    borderRadius: '10px',
    margin: '20px',
    cursor: 'pointer',
    fontSize:'400px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity:'50%',
      transition: 'all 0.6s ease',
      transform: 'scale(1.1)',
      boxShadow: 'inset 0 0 0 25px #000000',

    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },

  div: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  button:{
    position: 'absolute',
    display:'fixed',
  },
  divMapm: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  divMap: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflow:'scroll',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    overflowY:'scroll',
  },
  columnBack: {
    display: 'flex',
    width: '60%',
    alignItems: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    backgroundColor: '#eeefef',
    marginLeft: '5px',
    marginRight: '5px',
  },
});

const style = {
  flexDirection: 'column',
  overflow:'hidden',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '75%',
  maxHeight:'550px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function BasemapModal({ isBasemapModalVisible, setBaseMapModalVisible }: { isBasemapModalVisible: boolean, setBaseMapModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  if (!isBasemapModalVisible) {
    return (<></>);
  }
  const handleClose = () => setBaseMapModalVisible(false);
  const dispatch = useDispatch();
  const [baseMaps, setBaseMaps] = useState([]);
  const [showVoyager ,setShowVoyager] = useState(true);
  const [showClear ,setShowClear] = useState(true);
  const [showDark ,setShowDark] = useState(true);
  const [showNocturnal ,setShowNocturnal] = useState(false);
  const [showSimplifiedNight ,setShowSimplifiedNight] = useState(false);
  const [showTraffic ,setShowTraffic] = useState(false);
  const [showSatellite ,setShowSatellite] = useState(false);
  const [showDaytime ,setShowDaytime] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  //@ts-ignore
  const { companyUserSigned, setCompanyUserSigned } = React.useContext(GlobalContext);

  React.useEffect(() => {
    //TODO pegar os dados do idioma da empresa logada
    const listBaseMaps = async () => {
      const response = await BaseMapApi.getBaseMap()
       console.log(response.data);
       setBaseMaps(response.data);
    };
    //console.log(companyUserSigned)
    listBaseMaps()
  }, []);

  useEffect(()=>{
    
  },[baseMaps])

  function mapVoyager() {
    // @ts-ignore
    dispatch(setBasemap(VOYAGER));
    handleClose();
  }
  function mapPositron() {
    // @ts-ignore
    dispatch(setBasemap(POSITRON));
    handleClose();
  }
  function mapDark() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }
  function mapNocturnal() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }
  function mapSimplifiedNight() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }
  function mapTraffic() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }
  function mapSatellite() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }
  function mapDaytime() {
    // @ts-ignore
    dispatch(setBasemap(DARK_MATTER));
    handleClose();
  }

  function gotoBahia() {
    dispatch(setViewState({
      latitude: -14.853614,
      longitude: -40.852451,
      zoom: 12
    }));
    handleClose();
  }

  return (
    <Modal open={isBasemapModalVisible} onClose={handleClose}>
      <Box sx={style}>
        <div>
          <h1>{t('basemap-user.title')}</h1>
        </div>
        <Box className={classes.row}>
            {showVoyager?  
            <div className={classes.columnBack}>
              <img className={classes.img} src={Voyager} alt={t('basemap-user.voyager')} title={t('basemap-user.voyager')} onClick={mapVoyager}/>
              <strong className={classes.center}>{t('basemap-user.voyager')}</strong>
            </div>
            : <></>}
           
            {showClear?  
            <div className={classes.columnBack}>
              <img className={classes.img} src={Claro} alt={t('basemap-user.clear')} title={t('basemap-user.clear')}  onClick={mapPositron}/>
              <strong className={classes.center}>{t('basemap-user.clear')}</strong>
            </div>
            : <></>}

            {showDark?  
            <div className={classes.columnBack}>
              <img className={classes.img} src={Escuro} alt={t('basemap-user.dark')} title={t('basemap-user.dark')} onClick={mapDark}/>
              <strong className={classes.center}>{t('basemap-user.dark')}</strong>
            </div>
            : <></>}

            {showNocturnal?  
            <div className={classes.columnBack}>
                <img className={classes.img} src={Noturno} alt={t('basemap-user.nocturnal')} title={t('basemap-user.nocturnal')} onClick={mapNocturnal}/>
                <strong className={classes.center}>{t('basemap-user.nocturnal')}</strong>
              </div>
            : <></>}

              {showSimplifiedNight?  
              <div className={classes.columnBack}>
                <img className={classes.img} src={Noturno_simplificado} alt={t('basemap-user.simplified_night')} title={t('basemap-user.simplified_night')}  onClick={mapSimplifiedNight}/>
                <strong className={classes.center}>{t('basemap-user.simplified_night')}</strong>
              </div>
              : <></>}

              {showTraffic?  
              <div className={classes.columnBack}>
                <img className={classes.img} src={Transito} alt={t('basemap-user.traffic')} title={t('basemap-user.traffic')} onClick={mapTraffic}/>
                <strong className={classes.center}>{t('basemap-user.traffic')}</strong>
              </div>
              : <></>}

              {showSatellite?  
              <div className={classes.columnBack}>
                <img className={classes.img} src={Satelite} alt={t('basemap-user.satellite')} title={t('basemap-user.satellite')} onClick={mapTraffic}/>
                <strong className={classes.center}>{t('basemap-user.satellite')}</strong>
              </div>
              : <></>}

              {showDaytime?  
              <div className={classes.columnBack}>
                <img className={classes.img} src={Diurno} alt={t('basemap-user.daytime')} title={t('basemap-user.daytime')} onClick={mapTraffic}/>
                <strong className={classes.center}>{t('basemap-user.daytime')}</strong>
              </div>
              : <></>}

        </Box>

        <div className={classes.div}>
        <Button
          sx={{marginTop: '30px'}}
          className={classes.button}
          onClick={gotoBahia}
          variant='contained'>
          {t('basemap-user.set-map-position')}
        </Button>
        </div>
      </Box>
    </Modal>
  );
}
